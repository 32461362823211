<template>
	<div class="">
		<journal :activeNameF="activeNameF"></journal>
	</div>
</template>
<script>
	import journal from "@/views/journal/journal";
	export default {
		data() {
			return {
				activeNameF: "AHCI",
			};
		},
		components: {
			journal,
		},
		created() {},

		methods: {},
	};
</script>

<style lang="scss" scoped></style>
